<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="16">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t("message.complaint")}}</div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  <div class="mx-3">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                  </div>
                  <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.from_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.from')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.before')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" class="flex-style text-right mbtop5">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :can_create="false"
              
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>

              <th v-if="columns.manager_id.show">
                {{ columns.manager_id.title }}
              </th>

              <th v-if="columns.header.show">
                {{ columns.header.title }}
              </th>

              <th v-if="columns.title.show">
                {{ columns.title.title }}
              </th>

              <th v-if="columns.is_show.show">
                {{ columns.is_show.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <!-- <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.staff_id.show">
                <select-staff
                :size="'mini'"
                  v-model="filterForm.staff_id"
                  :class="mode ? 'input__day' : 'input__night'"
                  :id="filterForm.staff_id">
                </select-staff>
              </th>

              <th v-if="columns.manager_id.show">
                <select-staff
                :size="'mini'"
                  v-model="filterForm.manager_id"
                  :class="mode ? 'input__day' : 'input__night'"
                  :id="filterForm.manager_id">
                </select-staff>
              </th>

              <th v-if="columns.header.show">
                <crm-input
                  :placeholder="columns.header.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.header"
                ></crm-input>
              </th>

              <th v-if="columns.title.show">
                <crm-input
                  :placeholder="columns.title.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.title"
                ></crm-input>
              </th>

              <th v-if="columns.is_show.show">
                <el-select
                :placeholder="columns.is_show.title"
                class="d-block"
                v-model="filterForm.is_show"
                :class="mode ? 'filter__day' : 'filter__night'"
                :size="'small'"
                filterable
                clearable
                
              >
                <!-- <el-option
                
                  :label="$t('message.waiting')"
                  :value="'waiting'"
                ></el-option> -->
                <el-option
                
                  :label="$t('message.denied')"
                  :value="false"
                ></el-option>
                <el-option
                :label="$t('message.received')"
                  :value="true"
                ></el-option>
            </el-select>
                <!-- <crm-input
                  :placeholder="columns.is_show.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.is_show"
                ></crm-input> -->
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <!-- <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th> -->
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="complaint in list"
              :key="complaint.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ complaint.id }}</td>

              <td v-if="columns.staff_id.show">
                {{
                  complaint.staff
                    ? complaint.staff.name + " " + complaint.staff.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.manager_id.show">
                {{
                  complaint.manager
                    ? complaint.manager.name + " " + complaint.manager.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.header.show">
                {{ complaint.header }}
              </td>

              <td v-if="columns.title.show">
                {{ complaint.title }}
              </td>

              <td v-if="columns.is_show.show">
                {{ complaint.is_show }}
              </td>

              <td v-if="columns.created_at.show">
                {{ complaint.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ complaint.updated_at }}
              </td>

              <!-- <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="complaint"
                                    :actions="actions"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td> -->
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list],

  data() {
    return {};
  },
  mounted() {
    this.updateList();
  },
  computed: {
    ...mapGetters({
      list: "complaint/list",
      columns: "complaint/columns",
      pagination: "complaint/pagination",
      statues: "complaint/statues",
      filter: "complaint/filter",
      sort: "complaint/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "complaint/complaint",
      setPagination: "complaint/setPagination",
      updateSort: "complaint/updateSort",
      updateFilter: "complaint/updateFilter",
      updateColumn: "complaint/updateColumn",
      updatePagination: "complaint/updatePagination",
      show: "complaint/show",
      empty: "complaint/empty",
      delete: "complaint/destroy",
      refreshData: "complaint/refreshData",
    }),
  },
};
</script>
